var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"geko-base-detail-card mb-5",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('v-data-table',{staticClass:"rounded-xl elevation- pa-1 px-5",attrs:{"headers":_vm.configPlantingHoleDetail.table.header,"items":_vm.data.result.detailData,"search":''},scopedSlots:_vm._u([{key:"item.index",fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"top",fn:function(){return [_c('div',{staticClass:"list-header py-3 mt-1"},[_c('div',{staticClass:"pr-5 mr-5 d-flex flex-row",staticStyle:{"justify-content":"space-between"}},[_c('h4',[_vm._v("Detail Bibit Lubang Tanam")])]),_c('div',{staticClass:"d-flex flex-row geko-list-header-action"},[_c('div',{staticClass:"geko-list-header-toolbar"})])]),_c('div',{staticClass:"statistics mb-3"},[_c('div',{staticClass:"statistic-item light"},[_c('v-icon',[_vm._v("mdi-list-status")]),_c('div',{staticClass:"statistic-data"},[_c('p',{staticClass:"mb-0 label"},[_vm._v("Total Data Bibit")]),_c('p',{staticClass:"mb-0 value"},[_vm._v(_vm._s(_vm.data.result.detailData.length ?? ''))])])],1)])]},proxy:true}])})],1)],1),_c('v-col',[_c('v-card',{staticClass:"geko-base-detail-card mb-5 px-4",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('div',{staticClass:"list-header py-3 mt-1"},[_c('div',{staticClass:"pr-5 mr-5 d-flex flex-row",staticStyle:{"justify-content":"space-between"}},[_c('h4',[_vm._v("Tanda Tangan Petani")])]),_c('div',{staticClass:"d-flex flex-row geko-list-header-action"},[_c('div',{staticClass:"geko-list-header-toolbar"})])]),(true)?_c('div',{staticClass:"absent-photo-list d-flex flex-row"},[_c('div',{staticClass:"absent-photo-item",style:({
                    backgroundImage:
                        'url(' +
                        _vm.$_config.baseUrlUpload +
                        '/' +
                        _vm.data.result.farmer_signature ?? '' +
                        ')',
                    }),on:{"click":function($event){return _vm.showLightbox(_vm.$_config.baseUrlUpload + '/' + _vm.data.result.farmer_signature ?? '')}}},[_c('h6',[_vm._v("Foto Tandatangan Petani")])])]):_vm._e()]),_c('v-card',{staticClass:"geko-base-detail-card mb-5 px-4",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('div',{staticClass:"list-header py-3 mt-1"},[_c('div',{staticClass:"pr-5 mr-5 d-flex flex-row",staticStyle:{"justify-content":"space-between"}},[_c('h4',[_vm._v("Dokumentasi")])]),_c('div',{staticClass:"d-flex flex-row geko-list-header-action"},[_c('div',{staticClass:"geko-list-header-toolbar"})])]),(true)?_c('div',{staticClass:"absent-photo-list d-flex flex-row"},[_c('div',{staticClass:"absent-photo-item",style:({
              backgroundImage:
                'url(' +
                _vm.$_config.baseUrlUpload +
                '/' +
                _vm.data.result.gambar1 ?? '' +
                ')',
            }),on:{"click":function($event){return _vm.showLightbox(_vm.$_config.baseUrlUpload + '/' + _vm.data.result.gambar1 ?? '')}}},[_c('h6',[_vm._v("Foto Dokumentasi 1")])])]):_vm._e(),(true)?_c('div',{staticClass:"absent-photo-list d-flex flex-row"},[_c('div',{staticClass:"absent-photo-item",style:({
              backgroundImage:
                'url(' +
                _vm.$_config.baseUrlUpload +
                '/' +
                _vm.data.result.gambar2 ?? '' +
                ')',
            }),on:{"click":function($event){return _vm.showLightbox(_vm.$_config.baseUrlUpload + '/' + _vm.data.result.gambar2 ?? '')}}},[_c('h6',[_vm._v("Foto Dokumentasi 2")])])]):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }